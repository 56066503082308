<div>
  <div class="report">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <img src="../../assets/logo.jpg" alt="" />
          <h2 class="title">Report MAP Violation</h2>
        </div>
        <div class="card-body">
          <form [formGroup]="UserForm" (submit)="UsersInfo()">
            <div class="row">
              <div class="col-sm-6">
                <h4 class="mb-3">Who</h4>
                <div class="form-group position-relative">
                  <label for="webiste"
                    >Website (URL)
                    <span class="text-danger font-weight-bold h6"
                      >*</span
                    ></label
                  >
                  <input
                    #website
                    type="text"
                    class="form-control"
                    placeholder="Website URL"
                    formControlName="WebsiteUrl"
                  />
                  <span
                    class="error-notification"
                    *ngIf="
                      UserForm.controls['WebsiteUrl'].hasError('required') &&
                      isSubmited
                    "
                    >WebisteUrl is required</span
                  >
                  <span
                    class="error-notification"
                    *ngIf="
                      UserForm.controls['WebsiteUrl'].hasError('pattern') &&
                      website.value &&
                      isSubmited
                    "
                    >WebsiteUrl is not valid
                  </span>
                </div>
                <div class="form-group position-relative">
                  <label for="CName"
                    >Company Name
                    <span class="text-danger font-weight-bold h6"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="CName"
                    placeholder="Company Name"
                    formControlName="CompanyName"
                  />
                  <span
                    class="error-notification"
                    *ngIf="
                      UserForm.controls['CompanyName'].hasError('required') &&
                      isSubmited
                    "
                    >CompanyName is required</span
                  >
                </div>
                <div class="form-group position-relative">
                  <label for="address">Address</label>
                  <!-- <input type="text" class="form-control" id="address" placeholder="Address"
										formControlName="Address"> -->
                  <textarea
                    class="form-control"
                    id="comment"
                    placeholder="Address"
                    formControlName="Address"
                    rows="3"
                  ></textarea>
                </div>
                <div class="form-group position-relative">
                  <label for="PNumber">Phone Number</label>
                  <p-inputMask
                    mask="9999999999"
                    maxlength="15"
                    size="15"
                    class="form-control p-0 mask-pro-form-cntl"
                    formControlName="PhoneNumber"
                    placeholder="xxx-xxx-xxxx"
                  ></p-inputMask>

                  <!-- <input type="number" class="form-control" id="PNumber" placeholder="Phone Number" formcontrolname="PhoneNumber"> -->
                </div>
                <div class="form-group position-relative">
                  <label for="email">Email</label>

                  <input
                    #conem
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    formControlName="Email"
                  />
                  <span
                    class="error-notification"
                    *ngIf="
                      UserForm.controls['Email'].hasError('pattern') &&
                      conem.value &&
                      isSubmited
                    "
                    >Email is not valid
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <h4 class="mb-3">What</h4>
                <div class="form-group position-relative">
                  <label for="model"
                    >Model
                    <span class="text-danger font-weight-bold h6"
                      >*</span
                    ></label
                  >
                  <p-autoComplete
                    formControlName="Model"
                    class="form-control p-0"
                    (onSelect)="value($event)"
                    [suggestions]="results"
                    (completeMethod)="search($event)"
                    [minLength]="2"
                    (onBlur)="clearMap($event)"
                  >
                  </p-autoComplete>
                  <span
                    class="error-notification"
                    *ngIf="
                      UserForm.controls['Model'].hasError('required') &&
                      isSubmited
                    "
                    >Model is required</span
                  >
                </div>
                <div class="form-group position-relative">
                  <label for="CMAP">Current MAP</label>
                  <input
                    type="text"
                    [attr.disabled]="Mapvalue"
                    class="form-control"
                    id="CMAP"
                    placeholder="Current MAP"
                    formControlName="CurrentMap"
                  />
                </div>
                <div class="form-group position-relative">
                  <label for="MAPPrice"
                    >MAP Price Found
                    <span class="text-danger font-weight-bold h6"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    (keypress)="numberOnly($event)"
                    class="form-control"
                    id="MAPPrice"
                    placeholder="MAP Price Found"
                    formControlName="MapPriceFound"
                  />
                  <span
                    class="error-notification"
                    *ngIf="
                      UserForm.controls['MapPriceFound'].hasError('required') &&
                      isSubmited
                    "
                    >MapPriceFound is required</span
                  >
                </div>
                <div class="form-group position-relative">
                  <label for="screenshot">Screenshot</label>
                  <img
                    class="img-thumbnail img-thumbnail--screen"
                    *ngIf="showTrue"
                    src="{{ ModelImage }}"
                    alt="User Image"
                  />
                  <div class="box">
                    <input
                      type="file"
                      #fileselect
                      id="fileInput"
                      class="form-control"
                      (change)="imageUpload($event)"
                    />

                    <button
                      class="close"
                      *ngIf="showCancelButton === true"
                      (click)="reset(fileselect)"
                    ></button>
                  </div>
                </div>
                <div class="form-group position-relative">
                  <label for="comments">Comments</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    id="comment"
                    formControlName="Comments"
                    placeholder="Comments"
                  ></textarea>
                </div>
              </div>
            </div>
            <!-- <div class="alert alert-danger" *ngIf="showError" role="alert">
							Wrong Model Info
						  </div> -->
            <div class="form-group text-center mt-4">
              <button type="submit" class="btn btn-secondary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <p-dialog
    header="Map Violation"
    [(visible)]="display"
    [contentStyle]="{ 'overflow-x': 'hidden' }"
    class="edit_modal_form"
    [style]="{ width: '58%' }"
    [responsive]="true"
    showEffect="fade"
    [modal]="true"
  >
    <div class="view-modal">
      <div class="row">
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Website (URL):</div>
            <div class="col-md-9">
              {{ this.UserForm.controls.WebsiteUrl.value }}
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">CompanyName:</div>
            <div class="col-md-9">
              {{ this.UserForm.controls.CompanyName.value }}
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Address:</div>
            <div class="col-md-9">
              {{ this.UserForm.controls.Address.value }}
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Phone Number:</div>
            <div class="col-md-9">
              {{ this.UserForm.controls.PhoneNumber.value }}
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Email:</div>
            <div class="col-md-9">{{ this.UserForm.controls.Email.value }}</div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Model:</div>
            <div class="col-md-9">{{ this.UserForm.controls.Model.value }}</div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Current MAP:</div>
            <div class="col-md-9">
              {{ this.UserForm.controls.CurrentMap.value }}
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">MAP Price Found:</div>
            <div class="col-md-9">
              {{ this.UserForm.controls.MapPriceFound.value }}
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Comments:</div>
            <div class="col-md-9">
              {{ this.UserForm.controls.Comments.value }}
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Website Screenshot:</div>
            <div class="col-md-9">
              <a href="{{ screenshot_capture }}" target="_blank">
                <img
                  class="img-thumbnail img-thumbnail--custom"
                  src="{{ screenshot_capture }}"
                  alt="User Image"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-3">Screenshot:</div>
            <div class="col-md-9">
              <a href="{{ ModelImage }}" target="_blank">
                <img
                  *ngIf="showTrue"
                  class="img-thumbnail img-thumbnail--custom"
                  src="{{ ModelImage }}"
                  alt="User Image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="row">
        <div class="col-sm-12 text-center">
          <p class="mb-2">
            Are you sure you want to submit this Map Violation?
          </p>
          <button
            class="btn btn-danger cancel-button mr-1"
            (click)="closeDialog()"
          >
            Cancel
          </button>
          <button
            class="btn btn-success success-button ml-1"
            (click)="SubmitViolation()"
          >
            Submit
          </button>
        </div>
      </div>
    </p-footer>
  </p-dialog>
</div>
