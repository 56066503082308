import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { API_URL } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  headers: any;
  newHeaders: any;
  private API_URL: string;

  constructor(private http: HttpClient) {
    this.headers = {
      'Content-Type': 'application/json',
    };
    this.newHeaders = {
      Accept: 'application/json',
    };
    this.API_URL = API_URL;
  }

  currentMap(data): Observable<any> {
    return this.http.post(
      this.API_URL + 'currentMap',
      { data: data },
      { headers: this.headers }
    );
  }

  getModels(keyword): Observable<any> {
    return this.http.post(
      this.API_URL + 'getModels',
      { keyword: keyword },
      { headers: this.headers }
    );
  }

  modelImage(data): Observable<any> {
    return this.http.post(this.API_URL + 'ModelImage', data);
  }

  screencapture(obj): Observable<any> {
    return this.http.post(
      this.API_URL + 'screencapture',
      { obj: obj },
      { headers: this.headers }
    );
  }

  submitdata(obj): Observable<any> {
    return this.http.post(this.API_URL + 'submitdata', obj);
  }
}
