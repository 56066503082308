import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapBreakFormComponent } from './map-break-form/map-break-form.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { ToastrService } from 'ngx-toastr';
import { DataService } from '../app/data.service';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  declarations: [AppComponent, MapBreakFormComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DialogModule,
    InputMaskModule,
    HttpClientModule,
    AutoCompleteModule,
    BlockUIModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
  ],
  providers: [DataService, ToastrService],
  bootstrap: [AppComponent],
})
export class AppModule {}
