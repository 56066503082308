
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const authority =
    window.location.protocol
    + '//'
    + window.location.hostname
    + ':'
    + window.location.port;

const apis: any = {
    'http://127.0.0.1:4200' : 'http://127.0.0.1:3000/api/',
    'http://map.howardmiller.com:':'http://map.howardmiller.com/api/',
    'https://map.howardmiller.com:':'https://map.howardmiller.com/api/',
    'http://map.hekman.com:':'http://map.hekman.com/api/',
    'https://map.hekman.com:':'https://map.hekman.com/api/',
    'http://map.ridgewayclocks.com:':'http://map.ridgewayclocks.com/api/',
    'https://map.ridgewayclocks.com:':'https://map.ridgewayclocks.com/api/',
    default: 'https://mapbreak.com/api/',
};

export const API_URL = authority in apis ? apis[authority] : apis.default;

export const URL_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
