import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { EMAIL_REGEX, URL_REGEX } from '../../constants/constants';
import { DataService } from '../data.service';

@Component({
  selector: 'app-map-break-form',
  templateUrl: './map-break-form.component.html',
  styleUrls: ['./map-break-form.component.scss'],
})
export class MapBreakFormComponent implements OnInit {
  @ViewChild('inputElement') inputElement: ElementRef;
  text: string;

  UserForm: UntypedFormGroup;

  ModelImage: any;

  showTrue: boolean = false;

  selectedPic: any;

  isSubmited: boolean = false;

  Mapvalue: boolean = false;

  results: string[];

  loading = false;

  screenshot_capture: any;

  WebsiteUrlCurrentvalue: any;
  showCancelButton: boolean = false;

  screenshot_condition: any;
  display: boolean = false;

  constructor(
    private formbuilder: UntypedFormBuilder,
    private dataservice: DataService,
    private toaster: ToastrService
  ) {}
  @BlockUI() blockUI: NgBlockUI;

  ngOnInit() {
    localStorage.clear();
    this.UserForm = this.formbuilder.group({
      WebsiteUrl: ['', [Validators.required, Validators.pattern(URL_REGEX)]],
      CompanyName: ['', Validators.required],
      MapPriceFound: ['', Validators.required],
      Address: [''],
      Email: ['', [Validators.pattern(EMAIL_REGEX)]],
      PhoneNumber: [''],
      CurrentMap: [''],
      Model: ['', Validators.required],
      Comments: [''],
      Screenshot: [''],
    });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if(event.target.value.split('.').length === 1) {
      if (charCode > 31 && (charCode < 46 || charCode === 47 || charCode > 57)) {
        return false;
      }
    } else {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
    }

    if(event.target.value.split('.').length === 2) {
      if(event.target.value.split('.')[1].length < 2) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  imageUpload(event) {
    this.selectedPic = event.target.files[0];

    var formData = new FormData();
    formData.append('image', this.selectedPic);
    if (/^image\//.test(this.selectedPic.type)) {
      this.showCancelButton = true;
      this.dataservice.modelImage(formData).subscribe((result) => {
        if (result.code == 402) {
          (<HTMLInputElement>document.getElementById('fileInput')).value = null;
          this.showCancelButton = false;
          this.toaster.error('Please try again later', 'Something went wrong');
        } else if (result.code == 200) {
          if (result.data) {
            this.ModelImage = `${result.data}`;

            this.showTrue = true;
            this.selectedPic = '';
          }
        } else {
          this.toaster.error(result.message);
        }
      });
    } else {
      (<HTMLInputElement>document.getElementById('fileInput')).value = null;

      this.toaster.error('Please enter valid Image format');
    }
  }

  closeDialog() {
    this.display = false;
  }

  SubmitViolation() {
    this.blockUI.start('Loading...');
    let split = this.UserForm.controls['Model'].value.split(':');

    let obj = {
      WebsiteUrl: this.UserForm.controls['WebsiteUrl'].value,
      CompanyName: this.UserForm.controls['CompanyName'].value,
      MapPriceFound: +this.UserForm.controls['MapPriceFound'].value,
      Address: this.UserForm.controls['Address'].value,
      Email: this.UserForm.controls['Email'].value,
      PhoneNumber: this.UserForm.controls['PhoneNumber'].value
        ? this.UserForm.controls['PhoneNumber'].value
        : 0,
      CurrentMap: this.UserForm.controls['CurrentMap'].value,
      'Product Sku': split[0],
      'Product Name': split[1],
      Comments: this.UserForm.controls['Comments'].value,
      Screenshot: this.ModelImage ? this.ModelImage : '',
      WebsiteScreenshot: this.screenshot_capture,
    };

    this.dataservice.submitdata(obj).subscribe((result) => {
      if (result.code == 200 && result.data > 0) {
        this.blockUI.stop();

        localStorage.clear();
        this.UserForm.reset();
        this.ModelImage = '';
        this.showTrue = false;
        this.showCancelButton = false;
        (<HTMLInputElement>document.getElementById('fileInput')).value = null;
        this.toaster.success('Form submitted successfully', 'Success', {
          timeOut: 5000,
        });
        this.display = false;
      } else {
        if (result.code == 402) {
          this.blockUI.stop();
          this.display = false;
          this.toaster.error('Please try again later', 'Something went wrong', {
            timeOut: 3000,
          });
        }
      }
    });
  }

  UsersInfo() {
    this.isSubmited = false;
    if (this.UserForm.invalid) {
      this.isSubmited = true;
      return;
    }
    if (this.UserForm.controls['CurrentMap'].value == '') {
      this.UserForm.patchValue({
        Model: '',
      });
      this.toaster.error(
        'Please enter correct product number or product name',
        'Incorrect Model',
        { timeOut: 5000 }
      );
      return;
    }
    if (
      this.UserForm.controls['CurrentMap'].value <=
      this.UserForm.controls['MapPriceFound'].value
    ) {
      this.toaster.error(
        'Please check the price',
        'No violation found in Map',
        { timeOut: 3000 }
      );

      return;
    } else {
      if (
        localStorage.getItem('Website') ===
        this.UserForm.controls['WebsiteUrl'].value
      ) {
        this.display = true;
      } else {
        this.blockUI.start('Loading...');
        this.dataservice
          .screencapture(this.UserForm.controls['WebsiteUrl'].value)
          .subscribe((result) => {
            localStorage.setItem(
              'Website',
              this.UserForm.controls['WebsiteUrl'].value
            );

            if (result.code == 200) {
              this.blockUI.stop();
              this.screenshot_capture = result.data;

              this.display = true;
            } else {
              this.blockUI.stop();
              this.toaster.error(
                'Please try again later',
                'Something went wrong',
                { timeOut: 3000 }
              );
            }
          });
      }
    }
  }
  clearMap(event) {
    if (event.target.value == '') {
      this.UserForm.patchValue({
        CurrentMap: '',
      });
    }
  }

  search(event) {
    this.dataservice.getModels(event.query).subscribe((result) => {
      if (result.code == 200) {
        this.results = result.data;
      } else {
        this.results = [];
        this.UserForm.patchValue({
          CurrentMap: '',
          Model: '',
        });
        this.toaster.error(
          'Please enter correct product number or product name',
          'Incorrect Model',
          { timeOut: 5000 }
        );
      }
    });
  }

  value(v) {
    this.dataservice.currentMap(v).subscribe((result) => {
      this.UserForm.patchValue({
        CurrentMap: result.data,
      });

      this.Mapvalue = true;
    });
  }

  reset(element) {
    this.showCancelButton = false;
    element.value = '';
    this.ModelImage = '';
    this.showTrue = false;
  }
}
